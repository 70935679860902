function TechStack(){
    return(
        <div className="bg bg-white">
      <div className="mx-auto max-w-1700 py-16  sm:py-36 sm:px-6 ">
        <h2 className="text-4xl font-bold tracking-tight text-black">
        Our tech stack
        </h2>
        <div className="mt-10 flow-root lg:mt-10">
          <div className="-mt-4 -ml-8 flex flex-wrap justify-between lg:-ml-4">
            <div className="mt-4 ml-8 flex flex-shrink-0 flex-grow lg:ml-4 lg:flex-grow-0">
              <img className="h-14" src="https://bafybeigvj3z2lemqhvstdmbdgm25h7u2at6hdoh6lfbs5varmjf6su5yn4.ipfs.w3s.link/programacion.png" alt="Tuple" />
            </div>
            <div className="mt-4 ml-8 flex flex-shrink-0 flex-grow lg:ml-4 lg:flex-grow-0">
              <img className="h-16" src="https://bafybeiaion4ku4ijotulq7voowb7qoweegwwp6fhbpibvq4ct7w4u77w2m.ipfs.w3s.link/python.3.png"  />
            </div>
            <div className="mt-4 ml-8 flex flex-shrink-0 flex-grow lg:ml-4 lg:flex-grow-0">
                
              <img
                className="h-14"
                src="https://bafkreigon65p3nuldaqpblydizhzljc5jhesgubdmughcuypwfpaya7xhy.ipfs.dweb.link/?filename=react.png"
                alt="React"
                
              />
            </div>
            <div className="mt-4 ml-8 flex flex-shrink-0 flex-grow lg:ml-4 lg:flex-grow-0">
            
              <img
                className="h-14"
                src="https://bafybeihlb3qhxninabbq7lvslrkeruukpihfue4t7g6ytcv32npwbhaeje.ipfs.w3s.link/django-icon.jpeg"
                alt="django"
              />
            </div>
            <div className="mt-4 ml-8 flex flex-shrink-0 flex-grow lg:ml-4 lg:flex-grow-0">
              <img
                className="h-14"
                src="https://bafkreigs6zndzs5spg5yjziys6ypurln4pz3fw3ephv775iyesuoyolgei.ipfs.dweb.link/?filename=js.png"
                alt="Javasript"
              />
            </div>
            <div className="mt-4 ml-8 flex flex-shrink-0 flex-grow lg:ml-4 lg:flex-grow-0">
                
                <img
                  className="h-14"
                  src="https://bafybeifxknx6ifwy5kwx462iogyrble2t6o2xo2okufbt3yqm3bda7d7wi.ipfs.w3s.link/next-js-icon-logo-EE302D5DBD-seeklogo.com.png"
                  alt=" Next.js"
                  
                />
              </div>
          </div>
        </div>
      </div>
    </div>
    )
}
export default TechStack